import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the loginUser action as before
export const loginUser = createAsyncThunk(
  "auth/login-user",
  async (user, { rejectWithValue, dispatch }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/auth/login`,
        { user },
        { headers }
      );

      // Dispatch the VerifyUser action manually after login succeeds
      // dispatch(VerifyUser({ user: res.data.user, verificationCode: 1234, token: res.data.token }));

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Define the VerifyUser action as before
export const VerifyUser = createAsyncThunk(
  "auth/verify-user",
  async ({ user, verificationCode, token }, { rejectWithValue }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/auth/verify-user`,
        { user, verificationCode, token },
        { headers }
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logout-user",
  async ({ email, accessToken }) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: accessToken,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_API}/logout`,
      { email },
      { headers }
    );
    return res;
  }
);

export const checkToken = createAsyncThunk(
  "auth/token-check",
  async (token, { rejectWithValue }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };      

      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/check-token`,
        {},
        {
          headers,
        }
      );      
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/update-password",
  async ({ user, accessToken }, { rejectWithValue }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: accessToken,
      };
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_API}/update-password`,
        { user },
        { headers }
      );
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forget-password",
  async (email, { rejectWithValue }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/auth/forget-password`,
        { email },
        { headers }
      );
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async ({ password, resetToken }, { rejectWithValue }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/auth/reset-password`,
        { password, resetToken },
        { headers }
      );
      
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
