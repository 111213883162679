import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import ThemeProvider from "theme";
import ScrollToTop from "components/ScrollToTop";
import { BaseOptionChartStyle } from "components/chart/BaseOptionChart";
import Router from "routes/routes";
import Settings from "components/settings";
import ThemePrimaryColor from "components/ThemePrimaryColor";
import { ProgressBarStyle } from "components/LoadingScreen";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useDispatch, useSelector } from "react-redux";
import defaultLogo from "assets/icon.png";

import useAuth from "hooks/useAuth";
import IdleTimeOutHandler from "components/IdleTimeOut";
import ErrorBoundary from "./ErrorBoundary";
// import NoInternetPage from "./components/NoInternetPage"; // Import the error page component
import axios from "axios";
import { getSystemInfo } from "redux/slices/systemInfo.slice";

const queryClient = new QueryClient();

function App() {
  const dispatch = useDispatch();
  const { user, accessToken } = useAuth();
  const { systemInfo } = useSelector((state) => state.systemInfo);
  const systemLogo = document.getElementById("systemLogo");
  systemLogo.href = defaultLogo;

  const [isActive, setIsActive] = useState(true);
  const [isLogout, setLogout] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false); // Tracks if the device is authorized

  // Check if the device is authorized
  // useEffect(() => {
  //   const checkDeviceAuthorization = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BACKEND_API}/verify-device`
  //       );

  //       if (response.status === 200) {
  //         setIsAuthorized(true); // Device is authorized
  //       } else setIsAuthorized(false);
  //     } catch (error) {
  //       if (error.response?.status === 403) {
  //         setIsAuthorized(false); // Device is blocked
  //       } else {
  //         console.error("Error verifying device:", error);
  //         setIsAuthorized(null); // Error state
  //       }
  //     }
  //   };

  //   checkDeviceAuthorization();
  // }, []);

  useEffect(() => {
    dispatch(getSystemInfo({}));
  }, [dispatch, accessToken]);  

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          {user?._id &&
            systemInfo?.data[0]?.autoLock &&
            user?.role?.name?.toLowerCase() !== "system monitoring" && (
              <IdleTimeOutHandler
                onActive={() => {
                  setIsActive(true);
                }}
                onIdle={async () => {
                  setIsActive(false);
                  // await dispatch(reset());
                }}
                onLogout={() => {
                  setLogout(true);
                }}
                timeOutInterval={systemInfo?.data[0]?.timeOut}
              />
            )}
          <ScrollToTop />
          <ThemePrimaryColor>
            <BaseOptionChartStyle />
            <ProgressBarStyle />
            <Settings />
            <Router />
            <Toaster
              gutter={8}
              position="top-center"
              reverseOrder={false}
              toastOptions={{
                // Define default options
                className: "",
                duration: 6000,
              }}
            />
          </ThemePrimaryColor>
        </ThemeProvider>
        {process.env.NODE_ENV !== "development" && (
          <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
        )}
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
