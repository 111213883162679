import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from "redux/slices/auth.slice";
import UserAvatar from "pages/_directory/users/List/components/UserAvatar";
import useAuth from "hooks/useAuth";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import bcrypt from "bcryptjs-react";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { LockOpen, Logout, Visibility, VisibilityOff } from "@mui/icons-material";
import { getUsers } from "redux/slices/users.slice";

export const IdleTimeOutModal = ({ showModal, handleLogout, handleContinue, timeOut }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, accessToken } = useAuth();
  const { users } = useSelector((state) => state.user);
  const currentUser = users.data?.find((usr) => usr?._id === user?._id);
  const [showPassword, setShowPassword] = useState(false);
  const [idleTime, setIdleTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIdleTime((prev) => prev + 1); // Increment idle time in seconds
    }, 1000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  const formatIdleTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs}hr : ${mins}m : ${secs}s`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const password = e.target.password.value;
    if (await bcrypt.compareSync(password, currentUser?.password)) {
      localStorage.removeItem("locked");
      handleContinue();
    } else {
      toast.error("Invalid password");
      document.getElementById("unlockForm").reset();
    }
  };

  useEffect(() => {
    dispatch(getUsers({ accessToken }));
    return localStorage.setItem("locked", false);
  }, [dispatch, accessToken]);

  return (
    <Dialog
      open={showModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div style={{ display: "flex", marginLeft: "auto" }}>
          <UserAvatar
            photo={currentUser?.photo}
            sx={{ width: "80px", height: "80px", mr: 3 }}
          />
          <div style={{ marginTop: "auto", marginBottom: "auto", width: "100%" }}>
            <Typography>{currentUser?.name}</Typography>
            <Typography sx={{ color: "gray" }}>
              In idle: {formatIdleTime(idleTime)}
            </Typography>
          </div>
          <LockIcon sx={{ width: "20%", height: "60px", m: "auto 0 auto 0" }} />
        </div>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Hey there, you have been idle or inactive for some time. For your security, the system is auto-locked!
        </DialogContentText>
        <form onSubmit={handleSubmit} style={{ textAlign: "center" }} id="unlockForm">
          <OutlinedInput
            fullWidth
            required
            name="password"
            placeholder="Enter Your Password"
            type={showPassword ? "text" : "password"}
            sx={{ mt: "2%" }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />

          <Button
            startIcon={<Logout />}
            variant="outlined"
            color="error"
            sx={{ mt: "2%", width: "30%" }}
            onClick={handleLogout}
          >
            Logout
          </Button>
          <Button
            startIcon={<LockOpen />}
            variant="outlined"
            type="submit"
            sx={{ mt: "2%", ml: "5%", width: "30%" }}
          >
            Unlock
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
