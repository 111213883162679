import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { reset } from "redux/slices/auth.slice";
import toast from "react-hot-toast";
import axios from "axios";
import useAuth from "hooks/useAuth";

export default function LogOutDialog({
  onOpen,
  openDialog,
  onCloseDialog,
  onOpenDialog,
  
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {accessToken} = useAuth();

  const handleLogout = async () => {
    try {
      // Make the API call to logout
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/auth/logout`,
        {}, // Empty body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${accessToken}`,
          },
        }
      );

      // Check response status
      if (response.status === 200) toast.success("Logged out successfully");
      else toast.error("Logout failed on the server!");

      // Clear authentication state in Redux
      await dispatch(reset());

      // Clear browser data and navigate to login
      clearBrowserData();
      onCloseDialog(true);
      navigate("/auth/login", { replace: true });
    } catch (error) {
      toast.error("Error logging out!");
      console.error("Error logging out:", error);
    }
  };

  const handleCancelDialog = () => {
    onOpen(false);
    onCloseDialog(true);
    onOpenDialog(false);
  };

  const clearBrowserData = async () => {
    try {
      // Clear localStorage
      localStorage.clear();

      // Clear sessionStorage
      sessionStorage.clear();

      // Clear IndexedDB
      indexedDB.databases().then((databases) => {
        databases.forEach((db) => {
          indexedDB.deleteDatabase(db.name);
        });
      });

      // Clear Cache API
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map((name) => caches.delete(name)));
    } catch (error) {
      console.error("Error clearing browser data:", error);
    }
  };

  return (
    <Dialog open={openDialog} onClose={onCloseDialog}>
      <DialogTitle>Are you sure you want to logout?</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          You will be logged out of your account.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleCancelDialog()}>Cancel</Button>
        <Button onClick={handleLogout} color="error">
          Log Out
        </Button>
      </DialogActions>
    </Dialog>
  );
}
