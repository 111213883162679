import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { reset } from "redux/slices/auth.slice";
import LoadingScreen from "components/LoadingScreen";
import DashboardLayout from "layouts/dashboard";
import LogoOnlyLayout from "layouts/LogoOnlyLayout";
//
import GuestGuard from "guards/GuestGuard";
import PageBasedGuard from "guards/PageBasedGuard";
import AuthGuard from "guards/AuthGuard";
import NotFound from "components/Page404";
import ComingSoon from "components/ComingSoon";
import { useState } from "react";
import useAuth from "hooks/useAuth";
import TwoStepAuthentication from "pages/@auth/TwoStepAuthentication";
import UnauthorizedAccess from "pages/@dash/PlaceHolder";
import axios from "axios";
import toast from "react-hot-toast";
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

function LogOutDialog() {
  const [openDialog, setOpenDialog] = useState(true);
  const [onClose, setOnClose] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {accessToken} = useAuth();

  const handleLogout = async () => {
    try {
      // Make the API call to logout
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/auth/logout`,
        {}, // Empty body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${accessToken}`,
          },
        }
      );

      // Check response status
      if (response.status === 200) toast.success("Logged out successfully");
      else toast.error("Logout failed on the server!");

      // Clear authentication state in Redux
      await dispatch(reset());

      // Clear browser data and navigate to login
      clearBrowserData();
      navigate("/auth/login", { replace: true });
    } catch (error) {
      toast.error("Error logging out!");
      console.error("Error logging out:", error);
    }
  };

  const clearBrowserData = async () => {
    try {
      // Clear localStorage
      localStorage.clear();

      // Clear sessionStorage
      sessionStorage.clear();

      // Clear IndexedDB
      indexedDB.databases().then((databases) => {
        databases.forEach((db) => {
          indexedDB.deleteDatabase(db.name);
        });
      });

      // Clear Cache API
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map((name) => caches.delete(name)));
    } catch (error) {
      console.error("Error clearing browser data:", error);
    }
  };

  const handleCancelDialog = () => {
    setOnClose(true);
    setOpenDialog(false);
    navigate(-1);
  };

  return (
    <Dialog open={openDialog} onClose={onClose}>
      <DialogTitle>Are you sure you want to logout?</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          You will be logged out of your account.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleCancelDialog()}>Cancel</Button>
        <Button onClick={handleLogout} color="error">
          Log Out
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function Router() {
  const { user, accessToken } = useAuth();
  const { users } = useSelector((state) => state.user);
  const loggedUser = user;

  return useRoutes([
    {
      path: "/auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "logout",
          element: <LogOutDialog />,
        },
        {
          path: "forget-password",
          element: (
            <GuestGuard>
              <ForgetPassword />
            </GuestGuard>
          ),
        },
        {
          path: "reset-password/:resetToken",
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
        {
          path: "verification",
          element: <TwoStepAuthentication />,
        },
      ],
    },
    {
      path: "/admin",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),

      children: [
        { path: "unauthorized", element: <UnauthorizedAccess /> },

        { path: "account", element: <Account /> },
        // { path: "profile", element: <Profile /> },

        // receipts
        loggedUser?.role?.permissions?.includes("canViewReceipts")
          ? {
              path: "receipts/rbooks",
              element: <RbookList />,
            }
          : {
              path: "receipts/rbooks",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canCreateRbooks")
          ? {
              path: "receipts/rbooks/new-rbook",
              element: <RbookForm />,
            }
          : {
              path: "receipts/rbooks",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditRbooks")
          ? {
              path: "receipts/rbooks/edit-rbook/:_id",
              element: <RbookForm />,
            }
          : {
              path: "receipts/rbooks",
              element: <UnauthorizedAccess />,
            },

        loggedUser?.role?.permissions?.includes("canViewReceipts")
          ? {
              path: "receipts/receipts",
              element: <ReceiptList />,
            }
          : {
              path: "receipts/receipts",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canCreateReceipts")
          ? {
              path: "receipts/receipts/new-receipt",
              element: <ReceiptForm />,
            }
          : {
              path: "receipts/receipts",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditReceipts")
          ? {
              path: "receipts/receipts/edit-receipt/:_id",
              element: <ReceiptForm />,
            }
          : {
              path: "receipts/receipts",
              element: <UnauthorizedAccess />,
            },

        loggedUser?.role?.permissions?.includes("canViewFmis")
          ? {
              path: "receipts/fmis",
              element: <FmisList />,
            }
          : {
              path: "receipts/fmis",
              element: <UnauthorizedAccess />,
            },
        // loggedUser?.role?.permissions?.includes("canCreateFmis")
        //   ? {
        //     path: "receipts/fmis/new-fmis",
        //     element: <FmisForm />,
        //   }
        //   : {
        //     path: "receipts/fmis",
        //     element: <UnauthorizedAccess />,
        //   },
        // loggedUser?.role?.permissions?.includes("canEditFmis")
        //   ? {
        //     path: "receipts/fmis/edit-fmis/:_id",
        //     element: <FmisForm />,
        //   }
        //   : {
        //     path: "receipts/fmis",
        //     element: <UnauthorizedAccess />,
        //   },
        loggedUser?.role?.permissions?.includes("canViewMdaAgents")
          ? {
              path: "receipts/mdaAgent",
              element: <MdaAgentList />,
            }
          : {
              path: "receipts/mdaAgent",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canCreateMdaAgents")
          ? {
              path: "receipts/mdaAgent/new-mdaAgent",
              element: <MdaAgentForm />,
            }
          : {
              path: "receipts/mdaAgent",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditMdaAgents")
          ? {
              path: "receipts/mdaAgent/edit-mdaAgent/:_id",
              element: <MdaAgentForm />,
            }
          : {
              path: "receipts/mdaAgent",
              element: <UnauthorizedAccess />,
            },

        loggedUser?.role?.permissions?.includes("canViewReceiptIssues")
          ? {
              path: "receipts/receipt_issues/list",
              element: <ReceiptIssue />,
            }
          : {
              path: "receipts/receipt_issues/list",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canCreateReceiptIssues")
          ? {
              path: "receipts/receipt_issues/list/new-receiptIssue",
              element: <ReceiptIssueForm />,
            }
          : {
              path: "receipts/receipt_issues/list/new-receiptIssue",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditReceiptIssues")
          ? {
              path: "receipts/receipt_issues/list/edit-receiptIssue/:_id",
              element: <ReceiptIssueForm />,
            }
          : {
              path: "receipts/receipt_issues/list/edit-receiptIssue",
              element: <UnauthorizedAccess />,
            },

        // contracts
        loggedUser?.role?.permissions?.includes("canViewAnalytics")
          ? {
              path: "contracts/analytics",
              element: <Analytics />,
            }
          : {
              path: "contracts/analytics",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewContract")
          ? {
              path: "contracts/list",
              element: <ContractList />,
            }
          : {
              path: "contracts/list",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewContract")
          ? {
              path: "contracts/rejected",
              element: <RejectedList />,
            }
          : {
              path: "contracts/rejected",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewContract")
          ? {
              path: "contracts/archived",
              element: <ArchivedList />,
            }
          : {
              path: "contracts/archived",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canCreateContract")
          ? {
              path: "contracts/list/new-contract",
              element: <ContractForm />,
            }
          : {
              path: "contracts/list",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditContract") ||
        loggedUser?.role?.permissions?.includes("canReviewContracts")
          ? {
              path: "contracts/list/edit-contract/:_id",
              element: <ContractForm />,
            }
          : {
              path: "contracts/list",
              element: <UnauthorizedAccess />,
            },

        // audits
        loggedUser?.role?.permissions?.includes("canViewAuditAnalytics")
          ? {
              path: "audits/audit-analytics",
              element: <AuditAnalytics />,
            }
          : {
              path: "audits/audit-analytics",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewFinancialAudits")
          ? {
              path: "audits/financial-audits",
              element: <AuditStageList />,
            }
          : {
              path: "audits/financial-audits",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewComplianceAudits")
          ? {
              path: "audits/compliance-audits",
              element: <ComplianceStageList />,
            }
          : {
              path: "audits/compliance-audits",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewForensicAudits")
          ? {
              path: "audits/forensic-audits",
              element: <ForensicStageList />,
            }
          : {
              path: "audits/forensic-audits",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewPerformanceAudits")
          ? {
              path: "audits/performance-audits",
              element: <PerformanceStageList />,
            }
          : {
              path: "audits/performance-audits",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewInformationAudits")
          ? {
              path: "audits/isa",
              element: <ISAList />,
            }
          : {
              path: "audits/isa",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewSpecialAudits")
          ? {
              path: "audits/special-audits",
              element: <SpecialStageList />,
            }
          : {
              path: "audits/special-audits",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canCreateAuditStages")
          ? {
              path: "audits/financial-audits/new-auditStage",
              element: <AuditStageForm />,
            }
          : {
              path: "audits/financial-audits",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditAuditStages")
          ? {
              path: "audits/financial-audits/edit-auditStage/:_id",
              element: <AuditStageForm />,
            }
          : {
              path: "audits/financial-audits",
              element: <UnauthorizedAccess />,
            },

        loggedUser?.role?.permissions?.includes("canCreateAuditStages")
          ? {
              path: "audits/compliance-audits/new-auditStage",
              element: <AuditStageForm />,
            }
          : {
              path: "audits/compliance-audits",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditAuditStages")
          ? {
              path: "audits/compliance-audits/edit-auditStage/:_id",
              element: <AuditStageForm />,
            }
          : {
              path: "audits/compliance-audits",
              element: <UnauthorizedAccess />,
            },

        loggedUser?.role?.permissions?.includes("canCreateAuditStages")
          ? {
              path: "audits/performance-audits/new-auditStage",
              element: <AuditStageForm />,
            }
          : {
              path: "audits/performance-audits",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditAuditStages")
          ? {
              path: "audits/performance-audits/edit-auditStage/:_id",
              element: <AuditStageForm />,
            }
          : {
              path: "audits/performance-audits",
              element: <UnauthorizedAccess />,
            },

        loggedUser?.role?.permissions?.includes("canCreateAuditStages")
          ? {
              path: "audits/isa/new-auditStage",
              element: <AuditStageForm />,
            }
          : {
              path: "audits/isa",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditAuditStages")
          ? {
              path: "audits/isa/edit-auditStage/:_id",
              element: <AuditStageForm />,
            }
          : {
              path: "audits/isa",
              element: <UnauthorizedAccess />,
            },

        loggedUser?.role?.permissions?.includes("canCreateAuditStages")
          ? {
              path: "audits/special-audits/new-auditStage",
              element: <AuditStageForm />,
            }
          : {
              path: "audits/special-audits",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditAuditStages")
          ? {
              path: "audits/special-audits/edit-auditStage/:_id",
              element: <AuditStageForm />,
            }
          : {
              path: "audits/special-audits",
              element: <UnauthorizedAccess />,
            },

        loggedUser?.role?.permissions?.includes("canCreateAuditStages")
          ? {
              path: "audits/forensic-audits/new-auditStage",
              element: <AuditStageForm />,
            }
          : {
              path: "audits/forensic-audits",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditAuditStages")
          ? {
              path: "audits/forensic-audits/edit-auditStage/:_id",
              element: <AuditStageForm />,
            }
          : {
              path: "audits/forensic-audits",
              element: <UnauthorizedAccess />,
            },

        // calendar
        loggedUser?.role?.permissions?.includes("canViewCalendar")
          ? {
              path: "calendar/calendar",
              element: <Calendar />,
            }
          : {
              path: "calendar/calendar",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewCalendar")
          ? {
              path: "calendar/calendar2",
              element: <Calendar2 />,
            }
          : {
              path: "calendar/calendar",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewOutMembers")
          ? {
              path: "calendar/outMembers",
              element: <OutMemberList />,
            }
          : {
              path: "calendar/outMembers",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canCreateOutMembers")
          ? {
              path: "calendar/outMembers/new-outMember",
              element: <OutMemberForm />,
            }
          : {
              path: "calendar/outMembers",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditOutMembers")
          ? {
              path: "calendar/outMembers/edit-outMember/:_id",
              element: <OutMemberForm />,
            }
          : {
              path: "calendar/outMembers",
              element: <UnauthorizedAccess />,
            },

        // archive
        loggedUser?.role?.permissions?.includes("canViewFileManager")
          ? {
              path: "archives/file-manager",
              element: <FileManager />,
            }
          : {
              path: "archive/file-manager",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewFiles")
          ? {
              path: "archives/files",
              element: <FileList />,
            }
          : {
              path: "archive/files",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewArchive")
          ? {
              path: "archives/archive",
              element: <ArchiveList />,
            }
          : {
              path: "archives/archive",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditArchive")
          ? {
              path: "archives/archive/edit-archive/:_id",
              element: <ArchiveForm />,
            }
          : {
              path: "archives/archive",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditArchive")
          ? {
              path: "archives/archive/new-archive",
              element: <ArchiveForm />,
            }
          : {
              path: "archives/archive",
              element: <UnauthorizedAccess />,
            },

        // support
        loggedUser?.role?.permissions?.includes("canViewSupport")
          ? {
              path: "supports/analytics",
              element: <ComingSoon />,
            }
          : {
              path: "supports/analytics",
              element: <ComingSoon />,
            },
        loggedUser?.role?.permissions?.includes("canViewSupport")
          ? {
              path: "supports/list",
              element: <SupportList />,
            }
          : {
              path: "supports/list",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canCreateSupport")
          ? {
              path: "supports/list/new-support",
              element: <SupportForm />,
            }
          : {
              path: "supports/list",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditSupport")
          ? {
              path: "supports/list/edit-support/:_id",
              element: <SupportForm />,
            }
          : {
              path: "supports/list",
              element: <UnauthorizedAccess />,
            },
        // settings
        loggedUser?.role?.permissions?.includes("canViewMda")
          ? {
              path: "settings/mda",
              element: <MdaList />,
            }
          : {
              path: "settings/mda",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canCreateMda")
          ? {
              path: "settings/mda/new-mda",
              element: <MdaForm />,
            }
          : {
              path: "settings/mda",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditMda")
          ? {
              path: "settings/mda/edit-mda/:_id",
              element: <MdaForm />,
            }
          : {
              path: "settings/mda",
              element: <UnauthorizedAccess />,
            },

        loggedUser?.role?.permissions?.includes("canViewDevices")
          ? {
              path: "settings/devices",
              element: <DeviceList />,
            }
          : {
              path: "settings/devices",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canCreateDevices")
          ? {
              path: "settings/devices/new-device",
              element: <DeviceForm />,
            }
          : {
              path: "settings/devices",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditDevices")
          ? {
              path: "settings/devices/edit-device/:_id",
              element: <DeviceForm />,
            }
          : {
              path: "settings/devices",
              element: <UnauthorizedAccess />,
            },

        loggedUser?.role?.permissions?.includes("canViewUsers")
          ? {
              path: "settings/users",
              element: <UserList />,
            }
          : {
              path: "settings/users",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canCreateUsers")
          ? {
              path: "settings/users/new-user",
              element: <UserForm />,
            }
          : {
              path: "settings/users",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditUsers")
          ? {
              path: "settings/users/edit-user/:_id",
              element: <UserForm />,
            }
          : {
              path: "settings/users",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewRoles")
          ? {
              path: "settings/roles",
              element: <Roles />,
            }
          : {
              path: "settings/roles",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canCreateRoles")
          ? {
              path: "settings/roles/new-role",
              element: <RoleForm />,
            }
          : {
              path: "settings/roles",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditRoles")
          ? {
              path: "settings/roles/edit-role/:_id",
              element: <RoleForm />,
            }
          : {
              path: "settings/roles",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditSMS")
          ? {
              path: "settings/sms",
              element: (
                // <PageBasedGuard>
                <SmsForm />
                //</PageBasedGuard>
              ),
            }
          : {
              path: "settings/sms",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canEditSystemInfo")
          ? {
              path: "settings/systemInfo",
              element: <SystemInfo />,
            }
          : {
              path: "settings/systemInfo",
              element: <UnauthorizedAccess />,
            },
        loggedUser?.role?.permissions?.includes("canViewLogs")
          ? {
              path: "settings/logs",
              element: <LogsList />,
            }
          : {
              path: "settings/logs",
              element: <UnauthorizedAccess />,
            },

        // backup
        loggedUser?.role?.permissions?.includes("canViewBackups")
          ? {
              path: "settings/backups",
              element: <BackupList />,
            }
          : {
              path: "settings/backups",
              element: <UnauthorizedAccess />,
            },
      ],
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        ({ element: <Navigate to="/dashboard/app" replace /> },
        loggedUser?.role?.permissions?.includes("canViewDashboard")
          ? { path: "app", element: <DashboardApp /> }
          : {
              path: "/dashboard/app",
              element: <UnauthorizedAccess />,
            }),
      ],
    },

    {
      path: "/",
      // element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard/app" /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "coming-soon", element: <ComingSoon /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

const DashboardApp = Loadable(
  lazy(() => lazyRetry(() => import("pages/@dash/DashboardApp")))
);

// users
const UserList = Loadable(
  lazy(() => lazyRetry(() => import("pages/_directory/users/List/UserList")))
);
const UserForm = Loadable(
  lazy(() => lazyRetry(() => import("pages/_directory/users/Form/UserForm")))
);

// devices
const DeviceList = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/devices/List/DeviceList"))
  )
);
const DeviceForm = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/devices/Form/DeviceForm"))
  )
);

// receipts
const RbookList = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/receipt_books/List/RbookList"))
  )
);
const RbookForm = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/receipt_books/Form/RbookForm"))
  )
);
const ReceiptList = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/receipts/List/ReceiptList"))
  )
);
const ReceiptForm = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/receipts/Form/ReceiptForm"))
  )
);
const ReceiptIssue = Loadable(
  lazy(() =>
    lazyRetry(() =>
      import("pages/_directory/receipt_issues/List/ReceiptIssueList")
    )
  )
);
const ReceiptIssueForm = Loadable(
  lazy(() =>
    lazyRetry(() =>
      import("pages/_directory/receipt_issues/Form/ReceiptIssueForm")
    )
  )
);

const AuditAnalytics = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/audit_stages/List/AuditAnalytics"))
  )
);
const AuditStageList = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/audit_stages/List/AuditStageList"))
  )
);
const ForensicStageList = Loadable(
  lazy(() =>
    lazyRetry(() =>
      import("pages/_directory/audit_stages/List/ForensicAuditList")
    )
  )
);
const ComplianceStageList = Loadable(
  lazy(() =>
    lazyRetry(() =>
      import("pages/_directory/audit_stages/List/ComplianceAuditList")
    )
  )
);
const PerformanceStageList = Loadable(
  lazy(() =>
    lazyRetry(() =>
      import("pages/_directory/audit_stages/List/PerformanceAuditList")
    )
  )
);
const ISAList = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/audit_stages/List/ISAList"))
  )
);
const SpecialStageList = Loadable(
  lazy(() =>
    lazyRetry(() =>
      import("pages/_directory/audit_stages/List/SpecialAuditList")
    )
  )
);

const AuditStageForm = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/audit_stages/Form/AuditStageForm"))
  )
);

const MdaAgentList = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/mda_agents/List/AgentList"))
  )
);
const MdaAgentForm = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/mda_agents/Form/AgentForm"))
  )
);
const FmisList = Loadable(
  lazy(() => lazyRetry(() => import("pages/_directory/fmis/List/FmisList")))
);
// const FmisForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/fmis/Form/FmisForm"))));

const BackupList = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/backups/List/BackupList"))
  )
);

// calendar
const Calendar = Loadable(
  lazy(() => lazyRetry(() => import("pages/_directory/calendar/Calendar")))
);
const Calendar2 = Loadable(
  lazy(() => lazyRetry(() => import("pages/_directory/calendar/Calendar2")))
);
const OutMemberList = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/outMembers/List/OutMemberList"))
  )
);
const OutMemberForm = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/outMembers/Form/OutMemberForm"))
  )
);

// archive
const ArchiveList = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/archives/archive/ArchiveList"))
  )
);
const ArchiveForm = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/archives/archive/ArchiveForm"))
  )
);
const FileList = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/archives/files/List/FilesList"))
  )
);
const FileManager = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/archives/file-manager/List/FileManager"))
  )
);
// mda
const MdaList = Loadable(
  lazy(() => lazyRetry(() => import("pages/_directory/mda/List/MdaList")))
);
const MdaForm = Loadable(
  lazy(() => lazyRetry(() => import("pages/_directory/mda/Form/MdaForm")))
);

// contracts
const Analytics = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/contracts/List/ContractAnalytics"))
  )
);
const ContractList = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/contracts/List/ContractList"))
  )
);
const ContractForm = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/contracts/Form/ContractForm"))
  )
);
const ArchivedList = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/contracts/List/ArchivedContracts"))
  )
);
const RejectedList = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/contracts/List/RejectedContracts"))
  )
);

//support
const SupportList = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/support/List/SupportList"))
  )
);
const SupportForm = Loadable(
  lazy(() =>
    lazyRetry(() => import("pages/_directory/support/Form/SupportForm"))
  )
);

// mail and sms
const SmsForm = Loadable(
  lazy(() => lazyRetry(() => import("pages/_directory/sms/Form/SmsForm")))
);

// chats

// login
const Login = Loadable(
  lazy(() => lazyRetry(() => import("pages/@auth/Login")))
);
// const Register = Loadable(lazy(() => lazyRetry(() => import("views/@auth/Register")));
const Account = Loadable(lazy(() => lazyRetry(() => import("admin/Account"))));
const ForgetPassword = Loadable(
  lazy(() => lazyRetry(() => import("pages/@auth/ForgetPassword")))
);
const ResetPassword = Loadable(
  lazy(() => lazyRetry(() => import("pages/@auth/ResetPassword")))
);

// settings
// roles routes
const Roles = Loadable(
  lazy(() => lazyRetry(() => import("pages/_directory/roles/List/RoleList")))
);
const RoleForm = Loadable(
  lazy(() => lazyRetry(() => import("pages/_directory/roles/Form/RoleForm")))
);

const LogsList = Loadable(
  lazy(() => lazyRetry(() => import("pages/_directory/Logs/LogsList")))
);
const SystemInfo = Loadable(
  lazy(() => lazyRetry(() => import("pages/_directory/systemInfo/SystemInfo")))
);
