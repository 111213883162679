import { useRef, useState } from "react";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
} from "@mui/material";

// components
import MenuPopover from "components/MenuPopover";
// mocks_
import LogOutDialog from "components/LogOutDialog";
import styled from "@emotion/styled";
import Logo from "assets/logo.png";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme?.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { user, accessToken } = useSelector((state) => state.auth);
  // const { users } = useSelector((state) => state.user);
  const loggedUser = user;
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const MENU_OPTIONS = [];

  const [openDialog, setOpenDialog] = useState(false);
  const [onClose, setOnClose] = useState(false);

  const goToProfile = () => {
    setOpen(false);
    navigate("/admin/account");
  };
  const handleClickLogout = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.grey[100], 0.2),
          }}
          src={Logo}
          alt="profile"
        />
      </IconButton>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.0, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Stack>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={() => setOpen(null)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
        <MenuItem onClick={goToProfile} sx={{ m: 1 }}>
          Profile
        </MenuItem>
        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleClickLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
      {handleClickLogout && (
        <LogOutDialog
          onOpen={setOpen}
          onCloseDialog={setOnClose}
          onOpenDialog={setOpenDialog}
          onClose={onClose}
          openDialog={openDialog}
          accessToken={accessToken}
        />
      )}
    </>
  );
}
