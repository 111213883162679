import React, { useEffect, useState } from "react";
import moment from "moment";
import { IdleTimeOutModal } from "./IdleTimeOutModal";
import { reset } from "redux/slices/auth.slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import axios from "axios";
import toast from "react-hot-toast";

const IdleTimeOutHandler = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, accessToken } = useAuth();
  const locked = localStorage.getItem('locked');

  const [showModal, setShowModal] = useState(locked && user?._id);
  const [isLogout, setLogout] = useState(false);

  let timer = undefined;
  const events = ["click", "load", "keydown"];

  const eventHandler = (eventType) => {
    // console.log(eventType);
    if (!isLogout) {
      localStorage.setItem("lastInteractionTime", moment());
      if (timer) {
        props.onActive();
        startTimer();
      }
    }
  };  

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(
      () => {
        let lastInteractionTime = localStorage.getItem("lastInteractionTime");
        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime))
        );
        let timeOutInterval = props.timeOutInterval
          ? props.timeOutInterval
          : 600000;
        if (isLogout) {
          clearTimeout(timer);
        } else {
          if (diff.milliseconds < timeOutInterval) {
            startTimer();
            props.onActive();
          } else {
            // props.onIdle();
            setShowModal(true);
            localStorage.setItem('locked', true);
          }
        }
      },
      props.timeOutInterval ? props.timeOutInterval : 600000
    );
  };

  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });

    startTimer();
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  const handleContinueSession = () => {
    setShowModal(false);
    setLogout(false);
  };

  const handleLogout = async () => {
    removeEvents();
    clearTimeout(timer);
    setLogout(true);
    props.onLogout();
    setShowModal(false);
    try {
      // Make the API call to logout
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/auth/logout`,
        {}, // Empty body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${accessToken}`,
          },
        }
      );

      // Check response status
      if (response.status === 200) toast.success("Logged out successfully");
      else toast.error("Logout failed on the server!");

      // Clear authentication state in Redux
      await dispatch(reset());

      // Clear browser data and navigate to login
      clearBrowserData();
      navigate("/auth/login", { replace: true });
    } catch (error) {
      toast.error("Error logging out!");
      console.error("Error logging out:", error);
    }
  };

  const clearBrowserData = async () => {
    try {
      // Clear localStorage
      localStorage.clear();

      // Clear sessionStorage
      sessionStorage.clear();

      // Clear IndexedDB
      indexedDB.databases().then((databases) => {
        databases.forEach((db) => {
          indexedDB.deleteDatabase(db.name);
        });
      });

      // Clear Cache API
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map((name) => caches.delete(name)));
    } catch (error) {
      console.error("Error clearing browser data:", error);
    }
  };

  useEffect(() => {
    addEvents();
    return () => {
      removeEvents();
      clearTimeout(timer);
    };
  }, []);

  return (
    <div>
      <IdleTimeOutModal
        timeOut={props?.timeOutInterval || 600000}
        showModal={showModal}
        handleContinue={handleContinueSession}
        handleLogout={handleLogout}
      />
    </div>
  );
};

export default IdleTimeOutHandler;
