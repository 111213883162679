import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-hot-toast";
// component
import { useDispatch } from "react-redux";
import Iconify from "components/Iconify";
import { loginUser } from "redux/thunk/auth.thunk";
import { useSelector } from "react-redux";
import socket from "contexts/Socket";
import { setTfaEnabled } from "redux/slices/auth.slice";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [err, setErr] = useState(null);
  const { systemInfo } = useSelector((state) => state.systemInfo);

  const LoginSchema = Yup.object().shape({
    emailOrUsername: Yup.string().required("Email Or Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      emailOrUsername: "",
      password: "",
      remember: false,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (isSubmitting) {
          const res = await dispatch(loginUser(values));
          if (res.type === "auth/login-user/fulfilled") {
            socket?.emit("logsEvent");

            if (systemInfo?.data[0].twoFactor) {
              // Navigate to TFA verification and store relevant data
              dispatch(setTfaEnabled(true)); // Mark TFA as enabled
              navigate("/auth/verification", {
                state: { user: res.payload.user, auth: res.payload.auth },
              });
            } else {
              // Standard navigation after successful login
              if (
                res.payload.user?.role?.permissions?.includes(
                  "canViewDashboard"
                )
              ) {
                navigate("/dashboard/app", { replace: true });
              } else {
                navigate(res.payload.user?.role?.home_page, { replace: true });
              }
            }

            // Reset the form after successful login
            resetForm();
            setSubmitting(false);
            toast.success(res.payload.message);
          } else if (res.type === "auth/login-user/rejected") {
            toast.error(
              `${
                res
                  ? res.payload.response.data.error
                  : "Failed to connect due to network or server error"
              }`
            );

            // if (res?.payload?.response?.data?.blocked) window.location.reload();
          }
        }
      } catch (error) {
        toast.error(error.message || "An error occurred during login.");
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {err && <Alert severity="error">{err}</Alert>}
          <TextField
            fullWidth
            autoComplete="username"
            label="Username or Email address"
            {...getFieldProps("emailOrUsername")}
            error={Boolean(touched.emailOrUsername && errors.emailOrUsername)}
            helperText={touched.emailOrUsername && errors.emailOrUsername}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("remember")}
                checked={values.remember}
              />
            }
            label="Remember me"
          />

          <Link
            component={RouterLink}
            variant="subtitle2"
            to={"/auth/forget-password"}
            underline="hover"
          >
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
